import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import Letterize from "letterizejs";
import anime from "animejs/lib/anime.es.js";
import landingCss from "../styles/landing.module.css";
import astro from "../assets/img/astro.json";

export function LandingPage(props) {
  const titleRef = useRef(null);
  const messageRef = useRef(null);
  const animateMeRef = useRef(null);
  const ellipseContainerRef = useRef(null);

  useEffect(() => {
    document.body.style.overflowX = "hidden";

    const text = "WelcoMe to quizbud";
    const chars = text.split("");
    const $text = titleRef.current;
    $text.innerHTML = chars.map((c, i) => `<span>${c}</span>`).join("");
    const numChars = chars.length;
    const duration = 1600;
    const delay = duration / numChars;
    anime({
      targets: $text.querySelectorAll("span"),
      color: [
        { value: "#191654" },
        { value: "#43c6ac" },
        { value: "#FFFFFF" },
        { value: "#FFFFFF" },
        { value: "#43c6ac" },
      ],
      duration: duration,
      delay: anime.stagger(delay),
      easing: "linear",
      complete: function () {
        anime.set($text.querySelectorAll("span"), {
          color: "#43c6ac",
        });
        anime({
          targets: $text.querySelectorAll("span"),
          color: [
            { value: "#FFFFFF" },
            { value: "#D7BE69" },
            { value: "#0D5BE1" },
          ],
          duration: 2000,
          delay: anime.stagger(150),
          loop: true,
          easing: "linear",
          direction: "alternate",
          loopBegin: function (anim) {
            anim.began = true;
          },
          complete: function (anim) {
            if (anim.began) {
              anim.reset();
            }
          },
        });
      },
    });

    const test = new Letterize({
      targets: animateMeRef.current,
    });

    var animation = anime.timeline({
      targets: test.listAll,
      delay: anime.stagger(50),
      loop: true,
    });

    animation
      .add({
        translateY: -40,
      })
      .add({
        translateY: 0,
      });

    return () => {
      document.body.style.overflowX = "";
    };
  }, []);

  useEffect(() => {
    const text = "Ready to achieve more? Let’s begin! →";
    const chars = text.split("");
    const $text = messageRef.current;
    $text.innerHTML = chars.map((c, i) => `<span>${c}</span>`).join("");
    const numChars = chars.length;
    const duration = 1200;
    const delay = duration / numChars;

    anime({
      targets: $text.querySelectorAll("span"),
      color: [
        { value: "#191654" },
        { value: "#43c6ac" },
        { value: "#FFFFFF" },
        { value: "#fdb99b" },
        { value: "#cf8bf3" },
        { value: "#a770ef" },
      ],
      duration: duration,
      delay: anime.stagger(delay),
      easing: "linear",
      complete: function (anim) {
        anim.restart();
      },
    });
  }, []);

  return (
    <div>
      <div className={landingCss.welcomeContainer}>
        <div className={landingCss.titleContainer}>
          <div className={landingCss.title} ref={titleRef}>
            <div ref={animateMeRef} id="animateMe">
              Welcome to quizbud
            </div>
          </div>
          <div className={landingCss.astro}>
            <Lottie
              options={{
                animationData: astro,
                loop: true,
                autoplay: true,
              }}
            />
          </div>
        </div>
        <div className={landingCss.welcomeMessage}>
          QuizBud is your AI-powered, personalized learning companion. From
          generating tailored flashcards and comprehensive study notes to
          crafting quizzes and study guides, QuizBud adapts to meet your unique
          study needs. Harness innovative technology to streamline your learning
          and achieve academic excellence. Join the growing community of
          learners who are transforming their study habits and reaching their
          goals with QuizBud’s advanced tools.
        </div>
        <div
          className={landingCss.beginJourney}
          style={{ position: "relative", zIndex: 2 }}
        >
          <Link className={landingCss.beginJourneyLink} ref={messageRef}>
            Your path to success begins here – let’s get started!
          </Link>
        </div>
      </div>

      <div className={landingCss.ellipseContainer} ref={ellipseContainerRef}>
        <svg
          className={landingCss.svgEllipse}
          width="100%"
          height="100%"
          src="../assets/img/Ellipse.svg"
        ></svg>
      </div>
    </div>
  );
}
